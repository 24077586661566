import React, {useEffect, useState} from "react"
import Layout from "../components/js/layout"
import SEO from "../components/js/seo"
import queryString from "query-string"

import lecturesData from "../data/lectures.yaml"
import lecReviewsData from "../data/lec-reviews.yaml"

import "../components/css/page/lec-intro.css"

import BookBanner from '../components/js/reuseds/book-banner'
import Lectures from '../components/js/reuseds/lectures'

const LecIntro = ({data, location}) => {

  const [lecture, setLecture] = useState(null)
  const [reviews, setReviews] = useState([])
  const [screenWidth, setScreenWidth] = useState(1120)

  useEffect (() => {
    const code = queryString.parse(window.location.search).lec
    const _lecture = lecturesData.find((lec) => {
      return lec.code === code
    })

    if (!_lecture) return

    if (_lecture.discount) {
      const discount = _lecture.discount

      try {
        const today = new Date()
        const dateFrom = new Date(discount.split('~')[0])
        const dateTo = new Date(discount.split('~')[1] + ' 23:59:59')
        if (today > dateFrom && today < dateTo) {
          const diffDays = Math.floor((dateTo - today) / (1000 * 24 * 60 * 60))
          _lecture.discMsg = `D-${diffDays}`
        }
      } catch (e) {
        console.error(e)
      }
    }

    const _reviews = lecReviewsData.find((lecRev) => {
      return lecRev.code === code
    })
    .reviews.map((review) => {
      if (!review.writer) return review
      const firstChar = review.writer.charAt(0);
      const remainingChars = review.writer.slice(1).replace(/[^ ]/g, '*');
      const maskedWriter = firstChar + remainingChars;
      return {
        ...review,
        writer: maskedWriter
      };
    })

    setLecture(_lecture)
    setReviews(_reviews)

    if (window.screen.width < 1120) {
      setScreenWidth(window.screen.width)
    }

  }, [])

  return (
    <Layout page="lec-intro">
      <SEO title={lecture ? (lecture.title) : ''} />
      {
        lecture && (
          <section id="lecIntro">
            <iframe 
              width={screenWidth}
              height={screenWidth * 9 / 16}
              src={lecture.youtube} 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowfullscreen>
            </iframe>

            <div className='padded'>
              <h1>
                {lecture.discMsg && (
                  <span className='disc-msg'>
                    할인중
                    <strong>
                      {lecture.discMsg}
                    </strong>
                  </span>
                )}
                <span className='title'>
                  {lecture.title}
                </span>
              </h1>
              <div className='descs'>
                {lecture.desc.map((desc, i) => {
                  return <p key={i}>{desc}</p>
                })}
                  <br/>
                  <p>어설프게 공부해서는 살아남을 수 없는 <strong>🤖AI</strong> 시대.</p>
                  <p>설명대장 유튜버 <strong>😀얄코</strong>가 가장 쉽게, 그리고 가장 깊이 있게 알려드립니다.</p>
                  <div className='buttonSpace'>
                    <button 
                    className='inflearn'
                    onClick={() => window.open(lecture.link, '_blank').focus()}
                    >
                      👨‍🏫 인프런 강좌 보러가기
                    </button>
                    <button
                    className='coupon'
                    onClick={
                      () => window.open('/univ-dc?lecture=' + lecture.code, '_blank').focus()}
                    >
                      🎁 대학생 반값쿠폰 받기
                    </button>
                  </div>
              </div>
              <h2 className='freepart'>
                ▼ 무료파트<span>( 약 절반 분량 )</span> 보기
              </h2>
              <div className='descs'>
                <p>얄코 강의는 상당분량( 보통 절반 )이 유튜브에 무료공개되어 있습니다.</p>
                <p>🔍 나에게 맞는 강의인지 미리 살펴보고 전체강의 구매를 결정하실 수 있습니다.</p>

                <div className='buttonSpace'>
                  <button 
                  className='youtube'
                  onClick={() => window.open(
                    'https://youtube.com/playlist?list=PLpO7kx5DnyIGLkNn2wKDEudD-NqFTDthj',
                    '_blank'
                  ).focus()}
                  >
                    ▶ 유튜브에서 무료파트 보러가기
                  </button>
                </div>
              </div>

              <div className="reviews">
                {
                  reviews
                  .map((review, i) => {
                    return <article key={i}>
                      <a href={review.link} target="_blank">
                        <div className="writer">{review.writer}</div>
                        <div className="stars">⭐⭐⭐⭐⭐</div>
                        <p>{review.text}</p>
                      </a>
                    </article>
                  })
                }
              </div>

              <div className='book-banner-con'>
                <BookBanner></BookBanner>
              </div>
              <div className='lectures-con'>
                <Lectures></Lectures>
              </div>
            </div>
          </section>
        )
      }
    </Layout>
  )

}

export default LecIntro 