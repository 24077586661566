import React from "react"

import lecturesData from "./../../../data/lectures.yaml"

import "./../../css/reuseds/lectures.css"

class LecturesPage extends React.Component {

  insertLecture = (lecture, lecture_key) => (
    <article key={lecture_key}>
      <a target="_blank" href={`${lecture.link}`} key={lecture_key} rel="noreferrer">
        <div className="thumbnail">
          <div className="image" style={{
            backgroundImage: `url('/images/lectures/${lecture.code}/thumbnail.png')`
          }}></div>
        </div>
      </a>
        <div className="text">
          <h1>
            {lecture.discount && this.insertDiscount(lecture.discount)}
            {lecture.free && (
              <span className="banner free">무료</span>
            )}
            {lecture.coming && (
              <span className="banner coming">곧 오픈</span>
            )}
            {lecture.title}
          </h1>
          <div>
            {lecture.desc.map((line, key) => this.insertLines(line, key))}
          </div>
          <div className="buttons">
            {lecture.buttons.map((button, key) => this.insertButtons(button, key))}
          </div>
        </div>
    </article>
  )

  insertMobileLecture = (lecture, lecture_key) => (
    <article key={lecture_key}>
      <a href={`/mobile-lecture/?code=${lecture.code}`}>
        <div className="thumbnail">
          <div className="image" style={{
            backgroundImage: `url('/images/lectures/${lecture.code}/thumbnail.png')`
          }}></div>
        </div>
        <div className='desc'>
          <div className="title">{lecture.title}</div>
          <p>{lecture.desc[0]}</p>
          <p>{lecture.desc[1]}</p>
        </div>
      </a>
    </article>
  )

  insertLines = (line, key) => (
    <div key={key}>
      {line}
    </div>
  )
  insertButtons = (button, key) => (
    <a key={key} href={button.link} target="_blank" rel="noreferrer">
      {button.title}
    </a>
  )

  insertDiscount = (discount) => {
    try {
      const today = new Date()
      const dateFrom = new Date(discount.split('~')[0])
      const dateTo = new Date(discount.split('~')[1] + ' 23:59:59')
      if (today > dateFrom && today < dateTo) {
        const diffDays = Math.floor((dateTo - today) / (1000 * 24 * 60 * 60))
        return (
          <span className="discount">
            할인중 <b>D-{diffDays}</b>
          </span>
        )
      } else {
        return ('')
      }
    } catch (e) {
      console.error(e)
      return ('')
    }
  }

  render () {

    return (
      <section id="yalcoLectures" className="centered">
        <div className="list">
          {lecturesData.map(this.insertLecture)}
        </div>
        <div className="mobile-list">
          {lecturesData.map(this.insertMobileLecture)}
        </div>
      </section>
    )
  }
}

export default LecturesPage