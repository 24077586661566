import React from "react"

import './../../css/reuseds/book-banner.css'

import bannerHongong from './../../../images/banners/home_hongong.png'
import bannerGitGithub from './../../../images/banners/home_gitgithub.png'

const BookBanner = () => {
  return (
    <div id="yalcoBooks">
      <a href="https://product.kyobobook.co.kr/detail/S000203382595" target='_blank'>
        <div className="git-github"></div>
      </a>
      <a href="https://product.kyobobook.co.kr/detail/S000212053076" target='_blank'>
        <div className="html-css-js"></div>
      </a>
      <a href="https://product.kyobobook.co.kr/detail/S000213435960" target='_blank'>
        <div className="hongong-yalco"></div>
      </a>
    </div>
  )
}

export default BookBanner 